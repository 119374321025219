import * as React from 'react';
import Layout from '../components/layout';
import { centered, contentWrapper, heading } from '../components/layout.module.css';
import { halfCol, fullCol, row } from '../components/contact.module.css';
import { Seo } from '../components/seo';

const ContactPage = () => {
    return (
        <Layout pageTitle='Contact'>
            <h1 className={`${heading} ${centered}`}>Contact</h1>
            <div className={`${contentWrapper} ${centered}`}>
                <form /*action='https://submit-form.com/CnQTmy2T'*/>
                    <div className={row}>
                        <div className={halfCol}>
                            <input type='text' id='name' name='name' placeholder='Name' required='' />
                        </div>
                        <div className={halfCol}>
                            <input type='text' id='phone' name='phone' placeholder='Phone'></input>
                        </div>
                        <div className={fullCol}>
                            <input type='email' id='email' name='email' placeholder='Email' required='' />
                        </div>
                        <div className={fullCol}>
                            <textarea id='message' name='message' placeholder='Message' required=''></textarea>
                        </div>
                        <div className={fullCol}>
                            <button type='submit'>Send</button>
                            <p style={{ display: "inline" }}>Let's get in touch.</p>
                        </div>
                    </div>
                </form>
            </div>
        </Layout>
    );
};

export default ContactPage;

export const Head = () => <Seo title='Contact' />;
